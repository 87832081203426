"use client";

import { useEffect, useRef } from "react";
import { usePathname, useSearchParams } from "next/navigation";

import type { PageType } from "@schibsted/pulse-news-media";
import type { Article } from "../../types/content";
import type { ReadingContext } from "../../types/pulse";

import { usePulse } from "../../pulse/usePulse";
import { shouldViewArticleEvent, viewArticle } from "./helpers/viewArticle";
import { shouldViewFrontPage, viewFrontPage } from "./helpers/viewFrontPage";
import { shouldViewNewsMix, viewNewsMix } from "./helpers/viewNewsMix";
import {
  shouldViewSJPartnerPage,
  viewSJPartnerPage,
} from "./helpers/viewSJPartnerPage";
import { shouldViewCategoryEvent, viewCategory } from "./helpers/viewCategory";
import { shouldViewTopicEvent, viewTopic } from "./helpers/viewTopic";
import { shouldViewSearchEvent, viewSearch } from "./helpers/viewSearch";
import { isArticlePage } from "../../utils/page";

interface PulseViewEventsProps {
  type: PageType;
  id?: string;
  article?: Article;
  readingContext?: ReadingContext;
  isPremiumUser?: boolean;
}

const PulseViewEvent = ({
  type,
  id,
  article,
  readingContext,
  isPremiumUser,
}: PulseViewEventsProps) => {
  const {
    trackViewArticle,
    trackViewFrontpage,
    trackPageLeave,
    trackViewNewsMixPage,
    trackViewSJPartnerPage,
  } = usePulse();
  const searchParams = useSearchParams();
  const abortController = useRef<AbortController>();
  const pathname = usePathname();
  const runOnce = useRef(true);
  const previousPathname = useRef("/");

  useEffect(() => {
    const currentUrl = window.location.href;

    const isArticleViewEvent = shouldViewArticleEvent({
      type,
      article,
      readingContext,
      pathname,
      runOnce: runOnce.current,
    });

    const isFrontPageViewEvent = shouldViewFrontPage({
      type,
      id,
      pathname,
      runOnce: runOnce.current || isArticlePage(previousPathname.current),
    });

    const isNewsMIxViewEvent = shouldViewNewsMix({
      type,
      pathname,
      runOnce: runOnce.current,
    });

    const isSJPartnerPageEvent = shouldViewSJPartnerPage({
      type,
      pathname,
      runOnce: runOnce.current,
    });

    const isCategoryViewEvent = shouldViewCategoryEvent({
      type,
      pathname,
      id,
      runOnce: runOnce.current,
    });

    const isTopicViewEvent = shouldViewTopicEvent({
      type,
      pathname,
      id,
      runOnce: runOnce.current,
    });

    const isSearchViewEvent = shouldViewSearchEvent({
      type,
      id,
      runOnce: runOnce.current,
    });

    const onVisibilityChange = () => {
      if (document.hidden) {
        onBeforeUnload();
      }
    };

    const onBeforeUnload = () => {
      if (runOnce.current) {
        trackPageLeave();
        runOnce.current = false;
        if (abortController.current) {
          abortController.current.abort();
        }
      }
    };

    if (isFrontPageViewEvent) {
      viewFrontPage({ id, type, url: currentUrl, searchParams });

      runOnce.current = false;
    } else if (isArticleViewEvent) {
      viewArticle({
        article,
        isPremiumUser,
        readingContext,
        type,
        url: currentUrl,
        searchParams,
      });

      abortController.current = new AbortController();

      document.addEventListener("visibilitychange", onVisibilityChange, {
        signal: abortController.current.signal,
      });
      window.addEventListener("beforeunload", onBeforeUnload, {
        signal: abortController.current.signal,
      });

      runOnce.current = false;
    } else if (isNewsMIxViewEvent) {
      viewNewsMix({
        type,
        url: currentUrl,
        searchParams,
        runOnce: runOnce.current,
      });
      runOnce.current = false;
    } else if (isSJPartnerPageEvent) {
      viewSJPartnerPage({
        type,
        url: currentUrl,
        searchParams,
        runOnce: runOnce.current,
      });
      runOnce.current = false;
    } else if (isCategoryViewEvent) {
      viewCategory({ url: currentUrl, id, type, searchParams });

      runOnce.current = false;
    } else if (isTopicViewEvent) {
      viewTopic({ url: currentUrl, id, type, searchParams });

      runOnce.current = false;
    } else if (isSearchViewEvent) {
      viewSearch({ id, type, url: currentUrl, searchParams });

      runOnce.current = false;
    }

    previousPathname.current = pathname;

    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [
    trackViewFrontpage,
    trackViewArticle,
    trackPageLeave,
    article,
    readingContext,
    type,
    id,
    searchParams,
    pathname,
    trackViewNewsMixPage,
    trackViewSJPartnerPage,
    isPremiumUser,
  ]);

  return null;
};

export { PulseViewEvent };
