import type { ReadonlyURLSearchParams } from "next/navigation";
import type { PageType } from "@schibsted/pulse-news-media";

import { isSJPartnerPage } from "../../../utils/page";
import { updateObjectPage, updateOrigin } from "../../../pulse";
import { trackViewSJPartnerPage } from "../../../pulse/event/trackViewSJPartnerPage";

type IsViewSJPartnerPageCheck = {
  pathname: string;
  type: PageType;
  runOnce: boolean;
};

type ViewSJPartnerPage = {
  type: PageType;
  url: string;
  runOnce: boolean;
  searchParams: ReadonlyURLSearchParams;
};
const shouldViewSJPartnerPage = ({
  pathname,
  type,
  runOnce,
}: IsViewSJPartnerPageCheck) =>
  type === "Page" && isSJPartnerPage(pathname) && runOnce;

const viewSJPartnerPage = ({ type, url, searchParams }: ViewSJPartnerPage) => {
  const SJPartnerPageId = "sdrn:omnise:page:SJPartnerPage";
  const SJPartnerName = "SJ Partner Page";

  updateObjectPage({
    id: SJPartnerPageId,
    type,
    url,
    name: SJPartnerName,
  });

  trackViewSJPartnerPage(SJPartnerPageId, SJPartnerName);

  updateOrigin({
    type,
    url,
    source: searchParams.get("utm_source") || "undefined",
    channel:
      searchParams.get("utm_medium") ||
      searchParams.get("utm_channel") ||
      undefined,
    content: searchParams.get("utm_content") || undefined,
    campaign: searchParams.get("utm_campaign") || undefined,
    terms: searchParams.get("utm_terms") || undefined,
  });
};

export { shouldViewSJPartnerPage, viewSJPartnerPage };
